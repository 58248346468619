import { Currency, ETHER, JSBI, TokenAmount } from '@pancakeswap-libs/sdk'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, ChevronDownIcon, AddIcon, CardBody, Text, Heading } from '@pancakeswap-libs/uikit'
import CardNav from 'components/CardNav'
import Lottie from 'react-lottie'

import { LightCard } from 'components/Card'
import animationData from 'assets/lottie/comingsoon.json'

import { AutoColumn, ColumnCenter } from 'components/Column'
import CurrencyLogo from 'components/CurrencyLogo'
import { FindPoolTabs } from 'components/NavigationTabs'
import { MinimalPositionCard } from 'components/PositionCard'
import CurrencySearchModal from 'components/SearchModal/CurrencySearchModal'
import { PairState, usePair } from 'data/Reserves'
import { useActiveWeb3React } from 'hooks'
import { usePairAdder } from 'state/user/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { StyledInternalLink } from 'components/Shared'
import { currencyId } from 'utils/currencyId'
import TranslatedText from 'components/TranslatedText'
import AppBody from '../AppBody'
import { Dots } from '../Pool/styleds'

enum Fields {
  TOKEN0 = 0,
  TOKEN1 = 1,
}

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
}

export default function PoolFinder() {
  const { account } = useActiveWeb3React()

  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [activeField, setActiveField] = useState<number>(Fields.TOKEN1)

  const [currency0, setCurrency0] = useState<Currency | null>(ETHER)
  const [currency1, setCurrency1] = useState<Currency | null>(null)

  const [pairState, pair] = usePair(currency0 ?? undefined, currency1 ?? undefined)
  const addPair = usePairAdder()
  useEffect(() => {
    if (pair) {
      addPair(pair)
    }
  }, [pair, addPair])

  const validPairNoLiquidity: boolean =
    pairState === PairState.NOT_EXISTS ||
    Boolean(
      pairState === PairState.EXISTS &&
        pair &&
        JSBI.equal(pair.reserve0.raw, JSBI.BigInt(0)) &&
        JSBI.equal(pair.reserve1.raw, JSBI.BigInt(0))
    )

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, pair?.liquidityToken)
  const hasPosition = Boolean(position && JSBI.greaterThan(position.raw, JSBI.BigInt(0)))

  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      if (activeField === Fields.TOKEN0) {
        setCurrency0(currency)
      } else {
        setCurrency1(currency)
      }
    },
    [activeField]
  )

  const handleSearchDismiss = useCallback(() => {
    setShowSearch(false)
  }, [setShowSearch])

  const prerequisiteMessage = (
    <LightCard padding="45px 10px">
      <Text style={{ textAlign: 'center' }}>
        {!account ? 'Connect to a wallet to find pools' : 'Select a token to find your liquidity.'}
      </Text>
    </LightCard>
  )

  return (
    <>
      <div>
        <AppBody>
          <Lottie options={defaultOptions} height={400} width={400} />
          <div style={{ textAlign: 'center' ,padding:5}}>
          <Heading style={{ textAlign: 'center', marginTop: 10, fontSize: 50, color: '#553f85' }}>Stay Tuned</Heading>

          <ul
            style={{
              listStyle: 'none',
              display: 'flex',
              textAlign: 'center',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <li>
              <a href="https://t.me/bttswapfinance" rel="noreferrer" target="_blank">
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png"
                  alt="telegram"
                  style={{ width: '30px', height: '30px', marginLeft: '10px' }}
                />
              </a>
            </li>

            <a href="https://twitter.com/BttSwap" rel="noreferrer" target="_blank">
              <li>
                <img
                  src="https://cdn-icons-png.flaticon.com/512/733/733579.png"
                  alt="twitter"
                  style={{ width: '30px', height: '30px', marginLeft: '10px' }}
                />
              </li>
            </a>

            <li>
              <a href="https://medium.com/@bttswapfinance" rel="noreferrer" target="_blank">
                <img
                  src="https://cdn-icons-png.flaticon.com/512/174/174858.png"
                  alt="medium"
                  style={{ width: '30px', height: '30px', marginLeft: '10px' }}
                />
              </a>
            </li>
          </ul>
        </div>
        </AppBody>
      
      </div>
    </>
  )
}



