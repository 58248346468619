import { BaseLayout, Card } from '@pancakeswap-libs/uikit'
import React from 'react'
import styled from 'styled-components'



const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
//   margin-bottom: 48px;

  & > div {
    grid-column: span 6;
    width: 100%;
    margin:auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 6;
      width:80%;
    //   background:#fff !important;
      margin-left:auto;
    }
  }
`

function Footer() {



    return (
        <div style={{ background: "#000", position: "relative", bottom: "0", padding: "20px", color: "#fff", width: "100%", margin: "auto" }}>
            <Cards>
                <Card style={{ background: "#000", color: "#fff", padding: "10px", textAlign: "left" }}>
                    {/* <Cards>
                        <Card style={{ background: "#000", color: "#fff", padding: "10px", textAlign: "left" }}>
                            <div style={{ padding: "5px" }}>
                                Apps
                            </div>
                            <div style={{ padding: "5px" }}>
                                Wallet
                            </div>
                            <div style={{ padding: "5px" }}>
                                Bridge
                            </div>
                            <div style={{ padding: "5px" }}>
                                Staking
                            </div>
                            <div style={{ padding: "5px" }}>
                                Get BTT
                            </div>
                        </Card>
                        <Card style={{ background: "#000", color: "#fff", padding: "10px", textAlign: "left", marginLeft: "auto" }}>
                            <div style={{ padding: "5px" }}>
                                Dev Center
                            </div>
                            <div style={{ padding: "5px" }}>
                                Github
                            </div>
                            <div style={{ padding: "5px" }}>
                                Docs
                            </div>
                            <div style={{ padding: "5px" }}>
                                Faucet link
                            </div>
                            <div style={{ padding: "5px" }}>
                                Testnet
                            </div>
                        </Card>
                    </Cards> */}
                </Card>
                {/* <Card style={{ background: "#000", color: "#fff", padding: "10px", textAlign:  "left" }}>
                    hii
                </Card> */}
                <Card style={{ background: "#000", color: "#fff", padding: "10px", textAlign: "center" }}>
                    {/* <div style={{ fontSize: "28px" }}>
                        BitTorrent Chain
                    </div> */}
                    <div style={{ textAlign: "left" }}>

                        <ul style={{ listStyle: "none", display: "flex", textAlign: "left", justifyContent: "end", marginTop: "20px" }}>
                            <li>
                                <a href="https://t.me/bttswapfinance"  rel="noreferrer" target='_blank'>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1024px-Telegram_logo.svg.png" alt="telegram" style={{ width: "30px", height: "30px", marginLeft: "10px" }} />

                                </a>
                            </li>

                            <a href="https://twitter.com/BttSwap"   rel="noreferrer" target='_blank'>

                            <li>
                                <img src="https://cdn-icons-png.flaticon.com/512/733/733579.png" alt="twitter" style={{ width: "30px", height: "30px", marginLeft: "10px" }} />
                            </li>

                            </a>

                            <li>
                            <a href="https://medium.com/@bttswapfinance"  rel="noreferrer" target='_blank'>

                                <img src="https://cdn-icons-png.flaticon.com/512/174/174858.png" alt="medium" style={{ width: "30px", height: "30px", marginLeft: "10px" }} />
                            </a>
                            </li>
                          
                        </ul>
                    </div>
                </Card>
            </Cards>
        </div>
    )
}

export default Footer
