import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { BaseLayout, Card, CardBody, CardHeader, Heading } from '@pancakeswap-libs/uikit'
// import { Credentials, StringTranslations } from '@crowdin/crowdin-api-client'
import { Timeline } from 'react-twitter-widgets';
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import AddLiquidity from './AddLiquidity'
import {
  RedirectDuplicateTokenIds,
  RedirectOldAddLiquidityPathStructure,
  RedirectToAddLiquidity
} from './AddLiquidity/redirects'
// import MigrateV1 from './MigrateV1'
// import MigrateV1Exchange from './MigrateV1/MigrateV1Exchange'
// import RemoveV1Exchange from './MigrateV1/RemoveV1Exchange'
import Pool from './Pool'
import PoolFinder from './PoolFinder'
import ComingSoon from "./ComingSoon"
// import Farm from './Farm'
import RemoveLiquidity from './RemoveLiquidity'
import { RedirectOldRemoveLiquidityPathStructure } from './RemoveLiquidity/redirects'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
import { EN, allLanguages } from '../constants/localisation/languageCodes'
import { LanguageContext } from '../hooks/LanguageContext'
import { TranslationsContext } from '../hooks/TranslationsContext'

import Menu from '../components/Menu'
import Footer from './Footer'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  overflow-x: hidden;
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 32px 16px;
  min-height: calc(100vh - 152px);
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;

  background-image: url('/static/media/bg.bfd323f2.png');
  background-repeat: no-repeat;
  background-position: top;
  background-size: contain;
`

const Cards = styled(BaseLayout)`
  align-items: stretch;
  justify-content: stretch;
  margin-bottom: 48px;

  & > div {
    grid-column: span 6;
    width: 100%;
    margin:auto;
  }

  ${({ theme }) => theme.mediaQueries.sm} {
    & > div {
      grid-column: span 8;
    }
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    & > div {
      grid-column: span 6;
      width:80%;
    //   background:#fff !important;
      margin-left:auto;
    }
  }
`

const Marginer = styled.div`
  margin-top: 5rem;
`

export default function App() {
  const [selectedLanguage, setSelectedLanguage] = useState<any>(undefined)
  const [translatedLanguage, setTranslatedLanguage] = useState<any>(undefined)
  const [translations, setTranslations] = useState<Array<any>>([])
  // const apiKey = `${process.env.REACT_APP_CROWDIN_APIKEY}`
  // const projectId = parseInt(`${process.env.REACT_APP_CROWDIN_PROJECTID}`)
  // const fileId = 6
  //
  // const credentials: Credentials = {
  //   token: apiKey
  // }
  //
  // const stringTranslationsApi = new StringTranslations(credentials)
  //
  // const getStoredLang = (storedLangCode: string) => {
  //   return allLanguages.filter(language => {
  //     return language.code === storedLangCode
  //   })[0]
  // }

  // useEffect(() => {
  //   const storedLangCode = localStorage.getItem('pancakeSwapLanguage')
  //   if (storedLangCode) {
  //     const storedLang = getStoredLang(storedLangCode)
  //     setSelectedLanguage(storedLang)
  //   } else {
  //     setSelectedLanguage(EN)
  //   }
  // }, [])
  //
  // const fetchTranslationsForSelectedLanguage = async () => {
  //   stringTranslationsApi
  //     .listLanguageTranslations(projectId, selectedLanguage.code, undefined, fileId, 200)
  //     .then(translationApiResponse => {
  //       if (translationApiResponse.data.length < 1) {
  //         setTranslations(['error'])
  //       } else {
  //         setTranslations(translationApiResponse.data)
  //       }
  //     })
  //     .then(() => setTranslatedLanguage(selectedLanguage))
  //     .catch(error => {
  //       setTranslations(['error'])
  //       console.error(error)
  //     })
  // }
  //
  // useEffect(() => {
  //   if (selectedLanguage) {
  //     fetchTranslationsForSelectedLanguage()
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedLanguage])

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper>
          <LanguageContext.Provider
            value={{ selectedLanguage, setSelectedLanguage, translatedLanguage, setTranslatedLanguage }}
          >
            <TranslationsContext.Provider value={{ translations, setTranslations }}>
              <Menu>
                <Cards>
                  <Card style={{ maxWidth: "400px", marginTop:"70px" }}>
                    <CardHeader>
                      <Heading>
                        Announcement
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <Timeline
                        dataSource={{
                          sourceType: 'profile',
                          screenName: 'BttSwap'
                        }}
                        options={{
                          height: '330',
                          chrome: "noheader, nofooter",
                          width: "100%"
                        }}
                      />
                    </CardBody>
                  </Card>
                  <BodyWrapper>
                    <Popups />
                    <Web3ReactManager>
                      <Switch>
                        <Route exact strict path='/swap' component={Swap} />
                        <Route exact strict path='/swap/:outputCurrency' component={RedirectToSwap} />
                        <Route exact strict path='/send' component={RedirectPathToSwapOnly} />
                        <Route exact strict path='/find' component={PoolFinder} />
                        <Route exact strict path='/comingsoon' component={ComingSoon} />

                        
                       <Route exact strict path='/pool' component={Pool} />
                        <Route exact strict path='/create' component={RedirectToAddLiquidity} />
                        <Route exact path='/add' component={AddLiquidity} />
                        <Route exact path='/add/:currencyIdA' component={RedirectOldAddLiquidityPathStructure} />
                        <Route exact path='/add/:currencyIdA/:currencyIdB' component={RedirectDuplicateTokenIds} />
                        {/* <Route exact strict path='/remove/v1/:address' component={RemoveV1Exchange} /> */}
                        <Route exact strict path='/remove/:tokens' component={RedirectOldRemoveLiquidityPathStructure} />
                        <Route exact strict path='/remove/:currencyIdA/:currencyIdB' component={RemoveLiquidity} />
                        {/* <Route exact strict path='/migrate/v1' component={MigrateV1} />
                        <Route exact strict path='/migrate/v1/:address' component={MigrateV1Exchange} /> */}
                        <Route component={RedirectPathToSwapOnly} />
                      </Switch>
                    </Web3ReactManager>
                    <Marginer />
                    {/* <Footer /> */}
                  </BodyWrapper>

                </Cards>

              </Menu>
            </TranslationsContext.Provider>
          </LanguageContext.Provider>
          <Footer />
        </AppWrapper>
      </HashRouter>
    </Suspense>
  )
}
